@import "./_colors.sass";
@import "./_layout.sass";

$variables: (
	--theme-color: $themeColor,
	--theme-alternate-color: $themeAlternateColor,
	--theme-contrast-color: $themeContrastColor,
	--background-color: $bgColor,
	--background-color-darker: $bgColorDarker,
	--foreground-color: $foregroundColor,
	--foreground-color-darker: $foregroundColorDarker,
	--alternate-foreground-color: $alternateForegroundColor,
	--header-background-color: $headerBackgroundColor,
	--header-color: $headerColor,
	--header-strong-color: $headerStrongColor,
	--header-border-color: $headerBorderColor,
	--search-input-background-color: $searchInputBackgroundColor,
	--search-input-border-color: $searchInputBorderColor,
	--search-input-icon-color: $searchInputIconColor,
	--search-input-color: $searchInputColor,
	--search-input-strong-color: $searchInputStrongColor,
	--menu-background-color: $menuBackgroundColor,
	--menu-color: $menuColor,
	--menu-strong-color: $menuStrongColor,
	--menu-border-color: $menuBorderColor,
	--menu-selection-color: $menuSelectionColor,
	--menu-selection-border-color: $menuSelectionBorderColor,
	--menu-highlight-color: $menuHighlightColor,
	--menu-header-background-color: $menuHeaderBackgroundColor,
	--menu-header-color: $menuHeaderColor,
	--menu-header-strong-color: $menuHeaderStrongColor,
	--menu-header-border-bottom-color: $menuHeaderBorderBottomColor,
	--menu-header-border-right-color: $menuHeaderBorderRightColor,
	--border-color: $borderColor,
	--border-color-darker: $borderColorDarker,
	--highlight-color: $highlightColor,
	--widget-background-color: $widgetBackgroundColor,
	--widget-background-color-darker: $widgetBackgroundColorDarker,
	--widget-border-color: $widgetBorderColor,
	--widget-icon-color: $widgetIconColor,
	--body-font: $bodyFont,
	--body-font-weight: $bodyFontWeight,
	--titles-font: $titlesFont,
	--titles-font-weight: $titlesFontWeight,
	--text-color: $textColor,
	--strong-text-color: $strongTextColor,
	--strong-font-weight: $strongFontWeight,
	--input-color: $inputColor,
	--input-color-focus: $inputColorFocus,
	--input-border-color: $inputBorderColor,
	--input-border-color-focus: $inputBorderColorFocus,
	--input-background-color: $inputBackgroundColor,
	--input-background-color-focus: $inputBackgroundColorFocus,
	--input-options-color: $inputOptionsColor,
	--input-options-background-color: $inputOptionsBackgroundColor,
	--input-options-border-color: $inputOptionsBorderColor,
	--placeholder-color: $placeholderColor,
	--tabs-action-color: $tabsActionColor,
	--action-font-weight: $actionFontWeight,
	--action-color: $actionColor,
	--action-complementary-color: $actionComplementaryColor,
	--action-contrast-color: $actionConrastColor,
	--regular-color: $regularColor,
	--regular-second-color: $regularSecondColor,
	--regular-complementary-color: $regularComplementaryColor,
	--regular-contrast-color: $regularConrastColor,
	--link-color: $linkColor,
	--critical-color: $criticalColor,
	--high-color: $highColor,
	--medium-color: $mediumColor,
	--low-color: $lowColor,
	--success-color: $successColor,
	--error-color: $errorColor,
	--loading-color: $loadingColor,
	--legend-chart-color: $legendChartColor
);

$darkVariables: (
	--background-color: #333333,
	--background-color-darker: #303030,
	--foreground-color: #393939,
	--foreground-color-darker: #303030,
	--alternate-foreground-color: #343434,
	--header-background-color: #3f3f3f,
	--header-color: #aaaaaa,
	--header-strong-color: #eeeeee,
	--header-border-color: rgba(0, 0, 0, 0.1),
	--search-input-background-color: #333333,
	--search-input-border-color: #4f4f4f,
	--search-input-icon-color: #aaaaaa,
	--search-input-color: #aaaaaa,
	--search-input-strong-color: #eeeeee,
	--menu-background-color: #333333,
	--menu-color: #aaaaaa,
	--menu-strong-color: #eeeeee,
	--menu-border-color: rgba(0, 0, 0, 0.1),
	--menu-highlight-color: #393939,
	--menu-header-background-color: #363636,
	--menu-header-color: #aaaaaa,
	--menu-header-strong-color: #eeeeee,
	--menu-header-border-bottom-color: rgba(0, 0, 0, 0.1),
	--menu-header-border-right-color: rgba(0, 0, 0, 0.1),
	--border-color: #444444,
	--border-color-darker: #4f4f4f,
	--highlight-color: #444444,
	--widget-background-color: #444,
	--widget-background-color-darker: #393939,
	--widget-border-color: #555555,
	--text-color: #aaaaaa,
	--strong-text-color: #eeeeee,
	--input-color: #eeeeee,
	--input-background-color: #555555,
	--input-border-color: #404040,
	--regular-color: #3f3f3f,
	--regular-complementary-color: #eeeeee,
	--regular-contrast-color: #eeeeee
);

$colbiVariables: (
	--background-color: #eeeff2,
	--foreground-color: #eeeff2,
	--foreground-color-darker: #e9ebee,
	--alternate-foreground-color: #e9ebee,
	--header-background-color: #444d58,
	--header-color: #bcc2cb,
	--header-strong-color: #eeeeee,
	--header-border-color: rgba(255, 255, 255, 0.05),
	--menu-background-color: #444d58,
	--menu-color: #bcc2cb,
	--menu-strong-color: #eeeeee,
	--menu-border-color: rgba(255, 255, 255, 0.05),
	--menu-highlight-color: #5a6573,
	--menu-header-background-color: #444d58,
	--menu-header-color: #bcc2cb,
	--menu-header-strong-color: #eeeeee,
	--menu-header-border-color: rgba(255, 255, 255, 0.05),
	--widget-background-color: #ffffff,
	--widget-background-color-darker: #eeeeee,
	--border-color: rgba(0, 0, 0, 0.05),
	--border-color-darker: rgba(0, 0, 0, 0.1),
	--highlight-color: rgba(0, 0, 0, 0.1)
);

@function var($variable, $css4: true) {
	@if $css4 {
		@return unquote("var(" + $variable + ")");
	} @else {
		@return map-get($variables, $variable);
	}
}

@mixin css4($prop, $variable) {
	#{$prop}: var($variable, false);
	#{$prop}: var($variable);
}

$appBackground: var(
	--background-color
); // radial-gradient(circle at center top, var(--translucent-background-color), var(--translucent-background-color-darker)), linear-gradient(to bottom right, var(--background-color), var(--background-color-darker));
$appForeground: var(
	--foreground-color
); //linear-gradient(to right, var(--foreground-color), var(--foreground-color-darker));
