@import '~colbi_web_ui/lib/style/_layout';
@import '~colbi_web_ui/lib/style/_theme';
@import '~colbi_web_ui/lib/style/_mixins';

.header {
	position: sticky;
	display: flex;
	align-items: center;
	top: 0;
	height: min(40px, $headerHeight);
	padding: calc(#{$vMargin} / (2 * #{$phi})) calc(#{$hMargin} / (2 * #{$phi}));
	box-sizing: border-box;

	@include css4(background, --foreground-color);

	border-bottom: 1px solid var(--widget-border-color, false);
	border-bottom: 1px solid var(--widget-border-color);

	z-index: 1;

	h5 {
		margin: 0; }

	> * {
		display: inline-flex;
		position: relative;
		flex: 0 1 auto;
		min-width: 0;

		&:not(:first-child):not(:last-child) {
			flex: 1; }

		* {
			position: relative;
			white-space: nowrap;
			max-width: 100%;
			text-overflow: ellipsis;
			overflow: hidden; } } }

.close-button {
	display: inline-flex;
	align-items: center;
	margin: 0;
	padding: 0;
	width: 32px;
	height: 32px;
	background: none;
	border: none;
	outline: none;
	cursor: pointer;

	 :global(.icon) {
		width: 24px;
		height: 24px;

		@include css4(fill, --strong-text-color); } }

.actions {
	display: inline-flex;
	align-items: center;
	* + * {
		margin-left: 10px; } }

.actions-center {
	text-align: right;
	padding-top: 10px;
	* + * {
		margin-left: 10px; } }

.main {
	padding: calc(#{$vMargin} / (1 * #{$phi})) calc(#{$hMargin} / (1 * #{$phi})); }

.errors-container:not(:empty) {
	margin-bottom: calc(#{$vMargin} / (1 * #{$phi})); }

.error {
	border: 1px solid transparent;
	@include css4(border-color, --error-color);
	margin-bottom: 0.5em;
	border-radius: $inputRadius;
	padding: 0.5ch 1ch;

	small {
		font-size: small;
		@include css4(color, --error-color); } }


@include fullHD {
	.modal-header {
		padding: calc(#{$vMargin} / (2 * #{$phi})) calc(#{$maxHMargin} / (2 * #{$phi})); }
	.main {
		padding: calc(#{$vMargin} / (1 * #{$phi})) calc(#{$maxHMargin} / (1 * #{$phi})); } }
