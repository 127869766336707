@import "colbi_web_ui/lib/style/_theme.scss";
@import "colbi_web_ui/lib/style/_mixins.sass";

.title {
	margin: 0; }

.actions {
	margin-top: calc(#{$widgetPadding} / #{$phi});
	text-align: right; }

.action-button + .action-button {
	margin-left: calc(#{$widgetPadding} / #{$phi}); }

.options {
	display: flex;
	flex-wrap: wrap; }

.download {
	flex: auto;
	margin: 5px;
	display: block; }

.close {
	margin-top: 10px; }

.lines-limit {
	@include css4(color, --header-color);
	margin-left: calc(#{$widgetPadding} / #{$phi}); }

.group-content {
	display: flex;
	align-items: baseline;
	justify-content: space-between; }
