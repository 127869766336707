@import "../../../style/theme.scss";
@import "../../../style/mixins.sass";

@keyframes fade-in {
	from {
		opacity: 0; }

	to {
		opacity: 1; } }

@keyframes slide-in {
	from {
		transform: translateX(100%); }

	to {
		transform: translateX(0); } }

.backdrop {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: rgba(0, 0, 0, 0.4);
	opacity: 0;
	animation: fade-in 200ms linear forwards;
	backface-visibility: hidden;
	transform: translateZ(0); }

.dialog-window {
	position: fixed;

	top: $headerHeight;
	right: 0;
	left: 50%;

	width: 90vw;
	max-width: 500px;

	transform: translate(-50%, -10%);

	border: 1px solid transparent;
	@include css4(background, --widget-background-color);
	@include css4(border-color, --widget-border-color);
	border-radius: $widgetRadius;

	overflow: auto;
	-webkit-overflow-scrolling: touch;
	overscroll-behavior-y: contain; }

.dialog-content {
	padding: $widgetPadding;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	overscroll-behavior-y: contain; }

.dialog-header {
	position: sticky;
	display: flex;
	align-items: center;
	top: 0;
	height: $headerHeight;
	padding: $widgetPadding;
	box-sizing: border-box;

	@include css4(background, --widget-background-color);

	border-bottom: 1px solid var(--widget-border-color, false);
	border-bottom: 1px solid var(--widget-border-color); }
