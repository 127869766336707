@import '~colbi_web_ui/lib/style/_layout';
@import '~colbi_web_ui/lib/style/_theme';
@import '~colbi_web_ui/lib/style/_mixins';
​ {}
* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

.unauthorized-401 {
  @include css4(color, --header-color); }

.logout-button {
  border: none;
  background: transparent;
  padding: 0; }
​ {
  strong {
    font-weight: normal;
    text-decoration: underline; } }

.go-home {
  margin-left: 20px; }


.unauthorized-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100vh;
  flex-direction: column; }
