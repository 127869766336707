@import '~colbi_web_ui/lib/style/theme';
@import '~colbi_web_ui/lib/style/mixins';

.root {
	display: flex;
	overflow-y: auto;
	height: 100vh;

	.details {
		width: 440px; }

	.login {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		box-sizing: border-box;
		margin: auto;
		position: relative;
		@include css4(background-color, --login-background-center-color);
		height: 550px;
		padding: 0 10px;
		box-shadow: 0 10px 20px -8px rgba(0, 0, 0, 0.3);
		border-radius: $inputRadius; }

	.background {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		@include css4(background-image, --login-background-img);
		background-repeat: no-repeat;
		background-position: left top;
		pointer-events: none;
		-webkit-background-size: cover {
  		-moz-background-size: cover;
  		-o-background-size: cover;
  		background-size: cover; } } }

.version {
	position: absolute;
	bottom: 6px;
	right: 6px;
	small {
		font-size: 11px;
		@include css4(color,--login-text-color); } }


.container-sidebar {
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
	&.hide {
		display: none; } }

.login-actions {
	display: flex;
	align-items: center;
	justify-content: space-between;

	width: 100%;
	margin-top: calc(#{$vMargin} / #{$phi}); }

.language {
	border: 1px solid $borderColorDarker;
	border-radius: 0; }

.login-container {
	position: relative; }

.details {
	display: flex;
	flex-direction: column;
	padding: 0 calc(#{$hMargin} / #{$phi});
	box-sizing: border-box;
	width: 100%; }

.company-logo {
	@include css4(background-image, --login-img);
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	pointer-events: none;
	width: 420px;
	height: 100px;
	margin-bottom: 30px;
	z-index: 11; }

.title, .subtitle {
	@include css4(color,--login-text-color);
	margin-top: 0; }

.title {
	font-size: 1.8em;
	text-align: center; }

.sidebar .title {
	font-size: 2.4em;
	@include css4(color,--login-text-color);
	padding: 0 calc(#{$hMargin} / #{$phi}); }

.subtitle {
	padding: 0 calc(#{$hMargin} / #{$phi});
	margin-top: calc(#{$vMargin} / #{$phi} * 1.2);
	@include css4(color, --login-text-color);
	max-width: 440px; }

.item-link {
	padding: 0 calc(#{$hMargin} / #{$phi});
	margin-top: calc(#{$vMargin} / #{$phi} * 1.5);
	margin-bottom: calc(#{$vMargin} / #{$phi} * 1.5);
	cursor: pointer;
	font-weight: bold;
	@include css4(color, --login-text-color); }

.error {
	@include css4(background, --error-color);
	@include css4(color, --widget-background-color);
	padding: calc(#{$hMargin} / #{$phi} * 0.3);
	font-size: small;
	margin-top: calc(#{$vMargin} / #{$phi});
	margin-bottom: calc(#{$vMargin} / #{$phi});
	border-radius: $inputRadius;
	text-align: center; }

.success {
	@include css4(background, --success-color);
	@include css4(color, --widget-background-color);
	padding: calc(#{$hMargin} / #{$phi} * 0.3);
	font-size: small;
	margin-top: calc(#{$vMargin} / #{$phi});
	margin-bottom: calc(#{$vMargin} / #{$phi}); }

.form {
	position: relative;
	display: flex;
	flex-direction: column; }

.reset-actions {
	display: flex;
	align-items: center;
	justify-content: space-between;

	width: 100%;
	margin-top: calc(#{$vMargin} / #{$phi}); }

.login-action-button[primary=true] {
	@include css4(background-color, --login-button-color);
	@include css4(border-color, --login-button-color);

	 :global(.icon) {
		@include css4(fill, --login-strong-text-color); }
	strong {
		@include css4(color, --login-strong-text-color); }
	&:hover:not(:active) {
		@include css4(background-color, --login-button-background-color-focus);
		@include css4(border-color, --login-button-border-color-focus); } }

.login-text-input {
	h5 {
		@include css4(color, --login-text-color); } }

.title-with-logo {
	font-size: 2em;
	display: flex;
	justify-content: center; }

@include phone {
	.root {
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;

		.login {
			align-items: center;
			margin: 20px;
			height: 60%;
			padding: 0; }

		.background {
			background-position: center top; } }

	.details {
		max-width: 380px; }

	.company-logo {
		width: 250px;
		height: 110px;
		margin: 0; }

	.version {
		padding: 0 calc(#{$maxHMargin} / #{$phi}); } }


@include fullHD {
	.details {
		padding: 0 calc(#{$maxHMargin} / #{$phi}); } }
