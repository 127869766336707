@import '~colbi_web_ui/lib/style/theme';
@import '~colbi_web_ui/lib/style/mixins';

.root {
	display: flex;
	flex-flow: wrap;
	overflow-y: auto;
	height: 100vh;
	flex-direction: row-reverse;

	> * {
		flex: 1 1 100%; }

	.main {
		flex: 1 1 60%; }

	.aside {
		flex: 1 1 40%; }

	.details {
		max-width: 440px; }

	.main,
	.aside {
		display: flex;
		flex-direction: column;
		padding: $vMargin 0;
		align-items: center;
		justify-content: center;
		box-sizing: border-box; }

	.aside {
		position: relative;
		$_c1: var(--theme-color, false);
		$_c2: var(--theme-alternate-color, false);
		background-image: linear-gradient(-15deg, #{$_c1} 50%, #{$_c2} 100%);
		@supports(--css: variables) {
			$c1: var(--theme-color);
			$c2: var(--theme-alternate-color);
			background-image: linear-gradient(-15deg, #{$c1} 50%, #{$c2} 100%); }
		padding: 0 calc(#{$hMargin} / #{$phi} * 4);
		text-align: center; } }

.reset-actions {
	display: flex;
	flex-flow: wrap;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	margin-top: calc(#{$vMargin} / #{$phi}); }


.details {
	display: flex;
	flex-direction: column;
	padding: 0 calc(#{$hMargin} / #{$phi});
	box-sizing: border-box;
	width: 100%; }

.title, .subtitle {
	margin: 0; }

.title {
	font-size: 39px;
	text-align: left;
	width: 100%;

	.aside & {
		font-size: 2.4em;
		color: $regularColor; } }

.subtitle {
	margin-top: calc(#{$vMargin} / #{$phi} * 1.2);
	color: $regularColor;
	width: 100%;
	font-weight: bold; }

.msg-expired {
	margin-top: calc(#{$vMargin} / #{$phi} * 1);
	color: $regularColor;
	width: 100%; }

.item-link {
	margin-top: calc(#{$vMargin} / #{$phi} * 1.5);
	small {
		text-decoration: underline;
		color: $regularColor; } }

.error {
	@include css4(background, --error-color);
	@include css4(color, --widget-background-color);
	padding: calc(#{$hMargin} / #{$phi} * 0.3);
	font-size: small;
	margin-top: calc(#{$vMargin} / #{$phi}); }

.form {
	position: relative;
	display: flex;
	flex-direction: column;

	.action-button:disabled {
		opacity: 0.6;
		cursor: not-allowed; }

	.hide {
		display: none; } }

.msg-input-error {
	font-size: small;
	@include css4(color, --danger-border-color); }

.progress {
	height: 5px;
	margin-bottom: 20px;
	overflow: hidden;
	background-color: #f5f5f5;
	border-radius: 4px;
	-webkit-box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
	box-shadow: inset 0 1px 2px rgba(0,0,0,.1);

	.progress-bar {
		transition: width .6s ease;
		width: 0%;
		height: 100%;
		&.progress-bar-danger {
			width: 10%;
			@include css4(background, --success-color); }
		&.progress-bar-warning {
			width: 50%;
			@include css4(background, --success-color); }
		&.progress-bar-sucess-medium {
			width: 80%;
			@include css4(background, --success-color); }
		&.progress-bar-success {
			width: 100%;
			@include css4(background, --success-color); } } }

.subtitle {
	text-align: left; }

.wrap-strength-password {
	text-align: left;
	width: 100%;
	margin-left: -7px; }

.list-unstyled {
	list-style: none;
	padding: 0;
	margin: 15px 0 0;

	li {
		display: flex;
		align-items: center;
		@include css4(color, --regular-color);
		&.text-success {
			opacity: 0.4;
			svg {
 } }				//+css4(fill, --success-color)
		svg {
			width: 22px;
			height: 26px;
			@include css4(fill, --regular-color); }
		span {
			margin: 0;
			padding: 3px 0 0 3px; } } }

.logout-button {
	border: none;
	background: transparent;
	padding: 0;

	strong {
		font-weight: normal;
		text-decoration: underline; } }

.version {
	position: absolute;
	bottom: 6px;
	left: 6px;
	small {
		font-size: 11px;
		@include css4(color, --menu-strong-color); } }

@include phone {
	.root {
		justify-content: flex-start;
		flex-direction: column-reverse; }

	.details {} }


@include fullHD {
	.details {
		padding: 0 calc(#{$maxHMargin} / #{$phi}); } }
