@import "../../../style/_theme.scss";
@import "../../../style/_mixins.sass";

.password-input {
	min-width: 280px;
	width: 100%;
	z-index: 0;
	-webkit-text-security: disc;

	&:focus-within {
		z-index: 1; } }

.input-label {
	margin: 0.4rem 0; }

.input-field {
	margin-bottom: calc(#{$vMargin} / #{$phi});

	.label {
		margin-bottom: 0.4rem; }

	.dropdown > * {
		text-align: left;
		width: 100%; } }
