@import "./_layout.sass";

@mixin aspectRatio($h: 16, $v: 9) {
	position: relative;

	&::before {
		display: block;
		padding-top: unquote($v / $h * 100 + "%");
		content: ""; }

	& > * {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0; } }

@mixin text-clamp($lines: 2, $line-height: 0) {
	text-overflow: ellipsis;
	overflow: hidden;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: $lines;

	// Fallback for non-Webkit browsers
	// (won't show `…` at the end of the block)
	@if $line-height {
		max-height: calc(#{$line-height} * #{$lines} + 1px); } }

@mixin noScrollbars {
	overflow: -moz-scrollbars-none;
	-ms-overflow-style: none;

	&::-webkit-scrollbar {
		display: none; } }

@mixin dropShadow($shadow, $direction: top) {
	&::before {
		content: '';
		position: absolute;
		height: 40px;
		width: 100%;
		left: 0;
		#{$direction}: calc(100% - 40px);
		background: transparent;
		box-shadow: $shadow;
		z-index: -1; } }

@mixin phone {
	@media (max-width: #{$phone}) {
		@content; } }

@mixin tablet {
	@media (min-width: #{$phone + 1}) and (max-width: #{$tablet}) {
		@content; } }

@mixin fullHD {
	@media (min-width: #{$fullHD}) {
		@content; } }
