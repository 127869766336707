@import "colbi_web_ui/lib/style/_theme.scss";
@import "colbi_web_ui/lib/style/_mixins.sass";

.input {
	min-width: 280px;
	width: 100%;
	margin-bottom: calc(#{$vMargin} / #{$phi});
	z-index: 0;

	&:focus-within {
		z-index: 1; } }

.input-field {
	margin-bottom: calc(#{$vMargin} / #{$phi});

	.label {
		margin-bottom: 0.4rem; }

	.dropdown > * {
		text-align: left;
		width: 100%; } }

.group-content {
	margin-bottom: calc(#{$vMargin} / #{$phi} / 2); }

.group-content > .flex {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-left: calc(#{$hMargin} / (#{$phi} * -2));
	margin-right: calc(#{$hMargin} / (#{$phi} * -2));

	> * {
		flex: 1 1 40%; }

	.input {
		box-sizing: border-box;
		margin-top: calc(#{$vMargin} / #{$phi} / 2);
		margin-bottom: calc(#{$vMargin} / #{$phi} / 2);
		margin-left: calc((#{$hMargin} / (#{$phi} * 2)));
		margin-right: calc((#{$hMargin} / (#{$phi} * 2))); } }

@include fullHD {
	.group-content > .flex {
		margin-left: calc(#{$maxHMargin} / (#{$phi} * -2));
		margin-right: calc(#{$maxHMargin} / (#{$phi} * -2));

		.input {
			margin-top: 0;
			margin-left: calc(#{$maxHMargin} / (#{$phi} * 2));
			margin-right: calc(#{$maxHMargin} / (#{$phi} * 2)); } } }
