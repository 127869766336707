@import '~colbi_web_ui/lib/style/_layout';
@import '~colbi_web_ui/lib/style/_theme';
@import '~colbi_web_ui/lib/style/_mixins';

.finding-buttons {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 20px; }

.finding-button {
    margin-top: 15px;
    small {
        font-weight: bold; } }

.title {
    display: inline;
    height: 40px;
    line-height: 40px;
    margin: 0;

    &:first-letter {
	    text-transform: capitalize; } }
