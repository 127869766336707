// LAYOUT
$headerHeight: 48px;
$asideWidth: 220px;

$hMargin: 3vw;
$vMargin: 2.6rem;
$maxHMargin: 48px;
$contentMaxWidth: 1600px;
$inputRadius: 6px;

$listGap: 8px;
$mainGap: 0px;

$widgetPadding: 2ch;
$widgetRadius: 4px; //12px
$inputRadius: 3px; //8px
$regularShadow: 0 20px 30px -20px rgba(0, 0, 0, 0.2);
$smallShadow: 0 10px 20px -14px rgba(0, 0, 0, 0.1);
$smallShadowRight: 10px 0 20px -14px rgba(0, 0, 0, 0);
$smallShadowTop: 0 -10px 20px -12px rgba(0, 0, 0, 0.1);
$widgetShadow: 0 4px 20px -14px rgba(0, 0, 0, 0.1);

// FONT
$bodyFont: unquote('Roboto, sans-serif');
$titlesFont: unquote('Roboto, sans-serif');
$actionFont: unquote('Roboto, sans-serif');

$inputFontSize: small;
$inputFontSizeMobile: 16px;

$bodyFontWeight: 400;
$titlesFontWeight: 500;
$actionFontWeight: 500;
$strongFontWeight: 500;

$lineHeight: 130%;

// GLOBAL
$phi: 1.618;

// ANIMATIONS
$interactionTime: 150ms;


// BREAKPOINTS
$phone: 800px;
$tablet: 1100px;
$fullHD: $contentMaxWidth;
