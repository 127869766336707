@import '~colbi_web_ui/lib/style/_layout';
@import '~colbi_web_ui/lib/style/_theme';
@import '~colbi_web_ui/lib/style/_mixins';

.message {
	@include css4(background, --risk-background-color);
	@include css4(color, --risk-color);
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-weight: bold;
	padding: 0 30px;
	font-size: small;
	margin-bottom: 30px;
	&.message-success {
		background: #62a60a; }

	a {
		text-decoration: none;
		display: flex;
		align-items: center; }

	p,a small {
		color: white;
		font-weight: bold;
		height: 20px; } }
