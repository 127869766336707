@import "../../../style/_theme.scss";
@import "../../../style/_mixins.sass";

.content {
	padding: calc(#{$vMargin} / (2 * #{$phi})) calc(#{$hMargin} / (#{$phi})); }

.entry,
.inner-object {
	@include css4(background-color, --widget-background-color);
	padding: 10px;

	.label {
		display: block;
		font-size: small;
		white-space: nowrap;
		margin: 0;
		margin-bottom: 0.4em;
		font-weight: bold;
		@include css4(color, --strong-text-color);

		&::first-letter {
			text-transform: uppercase; } }

	.input {
		display: flex;
		align-items: center;
		min-height: 32px;
		padding: .5ch 1ch;
		box-sizing: border-box;
		line-height: 120%;
		border-radius: 3px;
		font-size: small;
		word-break: break-word;
		@include css4(background-color, --input-background-color);
		@include css4(color, --input-color); }

	+ .entry,
	+ .inner-object {
		margin-top: calc(#{$vMargin} / (2 * #{$phi})); } }

.inner-object {
	padding-left: calc(#{$hMargin} / (2 * #{$phi}));
	border-left: 1px solid var(--widget-border-color, false);
	border-left: 1px solid var(--widget-border-color);

	> .label {
		border-left: 2px solid var(--strong-text-color, false);
		border-left: 2px solid var(--strong-text-color);
		margin-left: calc(#{$hMargin} / (-2 * #{$phi}));
		padding-left: calc(#{$hMargin} / (2 * #{$phi})); }

	> .label + * {
		margin-left: calc(#{$hMargin} / (2 * #{$phi})); } }

@include fullHD {
	.content {
		padding: calc(#{$vMargin} / (2 * #{$phi})) calc(#{$maxHMargin} / (#{$phi})); }
	.inner-object {
		padding-left: calc(#{$maxHMargin} / (2 * #{$phi}));

		> .label {
			margin-left: calc(#{$maxHMargin} / (-2 * #{$phi}));
			padding-left: calc(#{$maxHMargin} / (2 * #{$phi})); }

		> .label + * {
			margin-left: calc(#{$maxHMargin} / (2 * #{$phi})); } } }
