@import "colbi_web_ui/lib/style/_theme.scss";
@import "colbi_web_ui/lib/style/_mixins.sass";


.title {
	margin: 0; }

.actions {
	margin-top: calc(#{$widgetPadding} / #{$phi});
	text-align: right; }

.action-button + .action-button {
	margin-left: calc(#{$widgetPadding} / #{$phi}); }
