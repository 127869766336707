@import '~colbi_web_ui/lib/style/_layout';
@import '~colbi_web_ui/lib/style/_theme';
@import '~colbi_web_ui/lib/style/_mixins';
​ {}
* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }
​ {}
.notfound {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;

  &.center-icon {
    flex-direction: column; }

  img {
    width: 435px;
    height: 442px;
    margin-right: 100px; } }

.notfound-404 {
  @include css4(color, --header-color); }

.logout-button {
  border: none;
  background: transparent;
  padding: 0; }
​ {
  strong {
    font-weight: normal;
    text-decoration: underline; } }

.go-home {
  margin-left: 20px; }

.img-404 {
  @include css4(background-image, --img-404);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  pointer-events: none;
  width: 150px;
  height: 200px; }
