@import "../../../style/theme.scss";
@import "../../../style/mixins.sass";

.root {
	display: flex;
	height: 40px;

	margin-bottom: calc(#{$vMargin} / #{$phi});

	transition: box-shadow 100ms linear;

	&.stuck {
		z-index: 2;

		margin-left: calc(#{$hMargin} * -1);
		margin-right: calc(#{$hMargin} * -1);
		padding-left: $hMargin;
		padding-right: $hMargin;

		background-color: var(--foreground-color, false);
		background: $appForeground;
		@include dropShadow($smallShadow); } }

.content {
    display: flex;
    height: 40px;

    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    overscroll-behavior-x: contain;
    @include noScrollbars; }

.tab {
	display: flex;
	align-items: center;

	@include css4(color, --text-color);

	text-decoration: none;
	text-transform: uppercase;
	font-family: $actionFont;
	font-weight: $actionFontWeight;

	white-space: nowrap;

	&.is-selected {
		@include css4(color, --strong-text-color);

		border-bottom: 2px solid var(--strong-text-color, false);
		border-bottom: 2px solid var(--strong-text-color); }

	+ .tab {
		margin-left: calc(#{$hMargin} / #{$phi}); } }

@include fullHD {
    .root.stuck {
        margin-left: calc(#{$maxHMargin} * -1);
        margin-right: calc(#{$maxHMargin} * -1);
        padding-left: $maxHMargin;
        padding-right: $maxHMargin; }

    .tab + .tab {
        margin-left: calc(#{$maxHMargin} / #{$phi}); } }


@media (min-width: calc(#{$contentMaxWidth} + 300px)) {
    .root.stuck {
        margin-left: calc(((90vw - #{$contentMaxWidth} - 140px) / -2) - #{$maxHMargin});
        padding-left: calc(((90vw - #{$contentMaxWidth} - 140px) / 2) + #{$maxHMargin});
        margin-right: calc(((90vw - #{$contentMaxWidth} - 140px) / -2) - #{$maxHMargin});
        padding-right: calc(((90vw - #{$contentMaxWidth} - 140px) / 2) + #{$maxHMargin}); } }
