@import "colbi_web_ui/lib/style/_theme.scss";
@import "colbi_web_ui/lib/style/_mixins.sass";

.input {
	min-width: 280px;
	width: 100%;
	z-index: 0;

	h5 {
		margin-top: 0; }

	&:focus-within {
		z-index: 1; } }

.input-field {
	margin-bottom: calc(#{$vMargin} / #{$phi});

	.label {
		margin-top: 0;
		margin-bottom: 0.4rem; }

	.dropdown > * {
		text-align: left;
		width: 100%; } }

.group-content {
	margin-bottom: calc(#{$vMargin} / #{$phi} / 2); }

.group-content > .flex {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	justify-content: space-between;
	margin-left: calc(#{$hMargin} / (#{$phi} * -2));
	margin-right: calc(#{$hMargin} / (#{$phi} * -2));

	> * {
		flex: 1 1 40%; }
	.input-field {
		margin-left: calc((#{$hMargin} / (#{$phi} * 2))); }

	.input {
		box-sizing: border-box;
		margin-top: 0;
		margin-bottom: 0;
		margin-right: calc((#{$hMargin} / (#{$phi} * 2))); } }

.content-entry,
.dependencie-entry {
	display: flex;
	justify-content: space-between;
	align-items: center;

	.input {
		margin-bottom: 0; }

	> * {
		flex: 1 1 30%;
		&:last-child {
			flex: 0 0 24px; }

		+ * {
			margin-left: 10px; } }

	+ .content-entry,
	+ .dependencie-entry {
		border-top: 1px solid transparent;
		@include css4(border-top-color, --border-color-darker);
		margin-top: 10px;
		padding-top: 10px; } }

.remove-button {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0;
	width: 24px;
	height: 24px;
	border-radius: 12px;

	background: transparent;
	appearance: none;
	outline: none;
	border: 1px solid transparent;
	box-sizing: border-box;

	@include css4(border-color, --strong-text-color);

	 :global(.icon) {
		width: 16px;
		height: 16px;
		@include css4(fill, --strong-text-color); }

	&:hover {
		@include css4(border-color, --action-color);
		 :global(.icon) {
			@include css4(fill, --action-color); } } }

.add-button {
	margin-top: 1rem;
	padding: 0;
	border: none;
	outline: none;
	text-decoration: underline;
	appearance: none;
	font-weight: $strongFontWeight;
	@include css4(color, --strong-text-color);
	background: transparent;
	small {
		font-size: small; } }

.project-entry .input {
	width: 100%;
	> * {
		text-align: left;
		width: 100%; } }

.inputs {
	> div {
		margin-top: calc(#{$vMargin} / #{$phi} * 2); }
	&.visible-0 {
		> div:last-child {
			display: none; } }
	&.visible-1 {
		> div:first-child {
			display: none; } } }

.tabs {
	display: flex;
	flex: 0 1 auto {
    align-items: center; } }

.tab {
	position: relative;
	display: flex;
	align-items: center;
	height: 100%;

	@include css4(color, --text-color);

	text-decoration: none;
	font-family: $actionFont;
	font-weight: $actionFontWeight;
	white-space: nowrap;
	cursor: pointer;

	*::first-letter {
		text-transform: uppercase; }

	small {
		font-size: small; }

	+ .tab {
		margin-left: 10px; }
	&::before,
	&::after, {
		content: ''; }

	&.is-selected {
		@include css4(color, --strong-text-color);

		&::after {
			position: absolute;
			content: '';
			display: block;
			height: 3px;
			left: -2px;
			right: -2px;
			top: calc(100% + (#{$widgetPadding} / #{$phi}));

			@include css4(background, --strong-text-color); }

		&::before {
			position: absolute;
			content: '';
			display: block;
			height: 2px;
			left: 0;
			right: 0;
			top: calc(100% + (#{$widgetPadding} / #{$phi}) + 3px);

			@include css4(background, --strong-text-color);
			filter: blur(2px);
			opacity: 0.4; } }

	&:not(.is-selected):hover {
		@include css4(color, --strong-text-color); } }


@include fullHD {
	.group-content > .flex {
		margin-left: calc(#{$maxHMargin} / (#{$phi} * -2));
		margin-right: calc(#{$maxHMargin} / (#{$phi} * -2));

		.input {
			margin-top: 0;
			margin-right: calc(#{$maxHMargin} / (#{$phi} * 2)); } } }
