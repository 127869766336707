// COLORS
$bgColor: #FFF;
$bgColorDarker: #E3E3E3;

$foregroundColor: #FFFFFF;
$foregroundColorDarker: #EEEEEE;
$alternateForegroundColor: #E9E9E9;

$headerBackgroundColor: #333333;
$headerColor: #AAAAAA;
$headerStrongColor: #EEEEEE;
$headerBorderColor: #555555;

$searchInputBackgroundColor: #222222;
$searchInputBorderColor: #444444;
$searchInputIconColor: #FFFFFF;
$searchInputColor: #EEEEEE;
$searchInputStrongColor: #FFFFFF;

$menuBackgroundColor: #EEEEEE;
$menuColor: #888888;
$menuStrongColor: #111111;
$menuBorderColor: rgba(0, 0, 0, 0.05);
$menuSelectionColor: #76AFBA;
$menuSelectionBorderColor: #76AFBA;
$menuHighlightColor: #F4F4F4;

$menuHeaderBackgroundColor: #333333;
$menuHeaderColor: #AAAAAA;
$menuHeaderStrongColor: #EEEEEE;
$menuHeaderBorderBottomColor: #333333;
$menuHeaderBorderRightColor: rgba(0, 0, 0, 0);

$borderColor: #E9E9E9;
$borderColorDarker: #D9D9D9;

$highlightColor: #F6F6F6;

$widgetBackgroundColor: #FEFEFE;
$widgetBackgroundColorDarker: #E9E9E9;
$widgetBorderColor: #E9E9E9;
$widgetIconColor: #76AFBA;

$textColor: #888888;
$strongTextColor: #111111;

$inputColor: #111111;
$inputColorFocus: #FFFFFF;
$inputBackgroundColor: #F6F6F6;
$inputBackgroundColorFocus: #76AFBA;
$inputBorderColor: #E6E6E6;
$inputBorderColorFocus: #65949C;

$inputOptionsColor: #FFF;
$inputOptionsBackgroundColor: #76AFBA;
$inputOptionsBorderColor: #74A4AD;

$placeholderColor: #999999;

$tabsActionColor: #2185D6;

$actionColor: #34A6B6;
$actionComplementaryColor: #76AFBA;
$actionConrastColor: #FFFFFF;

$regularColor: #FCFCFC;
$regularSecondColor: #8EA1B1;
$regularComplementaryColor: #111;
$regularConrastColor: #111;

$linkColor: #111111;

$themeColor: #62A60A;
$themeAlternateColor: #F49F1B;
$themeContrastColor: #FFFFFF;

$criticalColor: #EB0000;
$highColor: #f13e26;
$mediumColor: #f29f2d;
$lowColor: #9a9a9a;

$successColor: #83c100;
$errorColor: #f13e26;
$loadingColor: #979797;

$chartTooltipBackgroundColor: #8397A8;
$chartTooltipBorderColor: #8397A8;
$chartTooltipStrongTextColor: #191E24;
$chartTooltipTextColor: #000000;
$chartPrimaryColor: #62A60A;
$chartSecondaryColor: #8397A8;
$chartBackgroundColor: #EDF1F4;
$chartTextColor: #191E24;
$legendChartColor: #FFCC00;
