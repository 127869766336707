@import "colbi_web_ui/lib/style/_theme.scss";
@import "colbi_web_ui/lib/style/_mixins.sass";

.input {
	min-width: 280px;
	width: 100%;
	margin-bottom: calc(#{$vMargin} / #{$phi});
	z-index: 0;

	&:focus-within {
		z-index: 1; } }

.input-field {
	margin-bottom: calc(#{$vMargin} / #{$phi});

	.label {
		margin-bottom: 0.4rem; }

	.dropdown > * {
		text-align: left;
		width: 100%; } }

.group-content {
	margin-bottom: calc(#{$vMargin} / #{$phi} / 2); }

.group-content > .flex {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-left: calc(#{$hMargin} / (#{$phi} * -2));
	margin-right: calc(#{$hMargin} / (#{$phi} * -2));

	> * {
		flex: 1 1 40%; }

	.input {
		box-sizing: border-box;
		margin-top: calc(#{$vMargin} / #{$phi} / 2);
		margin-bottom: calc(#{$vMargin} / #{$phi} / 2);
		margin-left: calc((#{$hMargin} / (#{$phi} * 2)));
		margin-right: calc((#{$hMargin} / (#{$phi} * 2))); } }

.project-entry {
	display: flex;
	justify-content: space-between;
	align-items: center;

	> * {
		flex: 1 1 30%;
		&:last-child {
			flex: 0 0 24px; }

		+ * {
			margin-left: 10px; } }

	+ .project-entry {
		border-top: 1px solid transparent;
		@include css4(border-top-color, --border-color-darker);
		margin-top: 10px;
		padding-top: 10px; } }

.remove-button {
	display: flex;
	align-items: center;
	justify-content: center;

	padding: 0;
	width: 24px;
	height: 24px;
	border-radius: 12px;

	background: transparent;
	appearance: none;
	outline: none;
	border: 1px solid transparent;
	box-sizing: border-box;

	@include css4(border-color, --strong-text-color);

	 :global(.icon) {
		width: 16px;
		height: 16px;
		@include css4(fill, --strong-text-color); }

	&:hover {
		@include css4(border-color, --action-color);
		 :global(.icon) {
			@include css4(fill, --action-color); } } }

.add-button {
	margin-top: 1rem;
	padding: 0;
	border: none;
	outline: none;
	text-decoration: underline;
	appearance: none;
	font-weight: $strongFontWeight;
	@include css4(color, --strong-text-color);
	background: transparent;
	small {
		font-size: small; } }


.project-entry .dropdown {
	width: 100%;
	> * {
		text-align: left;
		width: 100%; } }

.error {
	border: 1px solid transparent;
	@include css4(border-color, --error-color);

	margin-bottom: calc(#{$vMargin} / (1 * #{$phi}));
	border-radius: $inputRadius;
	padding: 0.5ch 1ch;

	small {
		font-size: small;
		@include css4(color, --error-color); } }

.user-management {
	margin-top: 40px;
	border-top: 1px solid var(--widget-border-color);

	.title {
		flex: 0 0 auto; }

	.user-status-false {
    @include css4(color, --success-color);
    font-weight: bold;
    font-size: small;
    p {
        margin: 0; } }

	.user-status-true {
		@include css4(color, --error-color);
		font-weight: bold;
		font-size: small;
		p {
			margin: 0; } } }

@include fullHD {
	.group-content > .flex {
		margin-left: calc(#{$maxHMargin} / (#{$phi} * -2));
		margin-right: calc(#{$maxHMargin} / (#{$phi} * -2));

		.input {
			margin-top: 0;
			margin-left: calc(#{$maxHMargin} / (#{$phi} * 2));
			margin-right: calc(#{$maxHMargin} / (#{$phi} * 2)); } } }
