@import '~colbi_web_ui/lib/style/_layout';
@import '~colbi_web_ui/lib/style/_theme';
@import '~colbi_web_ui/lib/style/_mixins';

.loading-indicator {
	position: fixed;
	top: $headerHeight;
	z-index: 10;
	left: 50%;
	transform: translateX(-50%);
	padding: calc(#{$vMargin} / (#{$phi})) 0;

	pointer-events: none; }

.aside {
	position: fixed;
	left: 0;
	width: $asideWidth;
	top: 0;
	bottom: 0;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	align-items: center;

	@include css4(background, --menu-background-color);


	+ .main {
		margin-left: $asideWidth; } }

.header {
	z-index: 10; }

.project-link {
	width: 100%;
	text-decoration: none; }

.project {
	padding: calc(#{$vMargin} / (4 * #{$phi})) calc(#{$hMargin} / #{$phi} / 2);
	box-sizing: border-box;
	height: $rowHeight;
	* {
		white-space: nowrap;
		text-overflow: ellipsis;
		max-width: 100%;
		overflow: hidden;
		box-sizing: border-box; } }

.project-row {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	height: 100%;

	@include css4(color, --strong-text-color);

	h5 {
		margin: 0; }
	> * {
		flex: 0 1 20%;
		&:not(:global(.align-right)):not(:global(.align-left)) {
			text-align: center; }

		&:first-child:not(:global(.align-right)):not(:global(.align-left)) {
			text-align: left;
			flex: 1 1 50%; } } }

.is-active {
	.is-active-1 {
		color: var(--success-color); }
	.is-active-0 {
		color: var(--error-color); } }


.status-container {
	.status {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		padding: 4px 10px;
		width: 90px;
		box-sizing: border-box;
		font-size: x-small;
		text-overflow: ellipsis;
		overflow: hidden;
		border-radius: 4px;
		&.status-E,
		&.status-0,
		&.status-in_progress {
			@include css4(background-color, --action-background-color);
			@include css4(color, --action-color);
			font-weight: bold;
			font-size: 12px; }

		&.status-C,
		&.status-closed {
            color: #8dd5f7;
            border-color: #8dd5f7; }

		&.status-D {}
		&.status-1 {
			@include css4(background-color, --danger-background-color);
			@include css4(color, --action-color);
			font-weight: bold;
			font-size: 12px; }

		&.status-open {
            color: #dae258;
            border-color: #dae258; } } }

.logout-button {
	position: relative;
	display: flex;
	align-items: center;
	margin: 0;
	padding: 0;
	background: none;
	border: none;
	outline: none;
	font-size: small;
	text-transform: uppercase;
	cursor: pointer;
	border-radius: $inputRadius;

	 :global(.icon) {
		width: 24px;
		height: 24px;

		@include css4(fill, --header-strong-color); }

	* {
		@include css4(color, --header-strong-color); } }

.actions-separator {
	display: inline-flex;
	height: calc(#{$headerHeight} - 20px);
	width: 1px;
	margin: 0 calc(#{$hMargin} / (2 * #{$phi}));

	@include css4(background-color, --header-border-color); }

.content {
	padding: calc(#{$vMargin} / (1 * #{$phi})) $hMargin;
	box-sizing: border-box;

	> *:not([stuck]) {
		max-width: $contentMaxWidth;
		margin-left: auto;
		margin-right: auto; }

	> *[stuck] {
		margin-left: -#{$hMargin};
		margin-right: -#{$hMargin};
		padding-left: calc(#{$hMargin} / #{$phi});
		padding-right: calc(#{$hMargin} / #{$phi}); } }

.organization-group {
	border-radius: $widgetRadius;
	padding: 0 calc(#{$hMargin} / (2 * #{$phi}));
	padding-bottom: 10px;
	margin-bottom: calc(#{$vMargin} / #{$phi});
	@include css4(background-color, --foreground-color);
	border: 1px solid transparent;
	@include css4(border-color, --border-color-darker); }

.organization-title {
	position: sticky;
	display: flex;
	align-items: center;
	justify-content: space-between;
	top: $headerHeight;
	height: 40px;
	background-color: var(--foreground-color, false);
	background: $appForeground;
	z-index: 1;

	small {
		text-transform: uppercase;
		margin: 0; } }

@include phone {
	.main {
		margin-left: 0px !important; }
	.loading-indicator {
		padding-left: 0px !important; }

	.aside {
		display: none; } }


@include tablet {
	.main {
		margin-left: 0px !important; }
	.loading-indicator {
		padding-left: 0px !important; }

	.aside {
		display: none; } }


@include fullHD {

	.content {
		padding-left: $maxHMargin;
		padding-right: $maxHMargin;
		box-sizing: border-box;

		> *[stuck] {
			margin-left: -#{$maxHMargin};
			margin-right: -#{$maxHMargin};
			padding-left: calc(#{$maxHMargin});
			padding-right: calc(#{$maxHMargin}); } } }
