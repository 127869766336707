@import '~colbi_web_ui/lib/style/_layout';
@import '~colbi_web_ui/lib/style/_theme';
@import '~colbi_web_ui/lib/style/_mixins';

$animDuration: 200ms;

@keyframes fade-in {
	from {
		opacity: 0; }

	to {
		opacity: 1; } }

.backdrop {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: rgba(0, 0, 0, 0.4);
	opacity: 0;
	animation: fade-in 200ms linear forwards; }

@keyframes enter {
	to {
		transform: translateX(0); } }
@keyframes push-back {
	to {
		transform: translateX(-20px); } }

@keyframes leave {
	to {
		transform: translateX(100%); } }

.modal-window {
	position: fixed;
	top: 0;
	right: 0;
	width: 100vw;
	height: 100vh;
	max-width: 800px;

	@include css4(background, --foreground-color);

	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
	overscroll-behavior-y: contain;


	&.enter {
		transform: translateX(100%);
		animation: enter $animDuration ease-out forwards; }

	&.push-back {
		transform: translateX(0);
		animation: push-back $animDuration ease-out forwards; }
	&.push-front {
		transform: translateX(-20px);
		animation: enter $animDuration ease-out forwards; }

	&.leave {
		transform: translateX(0%);
		animation: leave $animDuration ease-in forwards; } }

.modal-center-window {
	position: fixed;

	top: $headerHeight;
	right: 0;
	left: 50%;

	width: 90vw;
	max-width: 520px;

	transform: translate(-50%, -10%);

	border: 1px solid transparent;
	@include css4(background, --foreground-color);
	@include css4(border-color, --widget-border-color);
	border-radius: $widgetRadius;

	overflow: auto;
	-webkit-overflow-scrolling: touch;
	overscroll-behavior-y: contain;

	&.push-back {
		animation: translateX(0); }

	&.push-front {
		animation: leave; }

	&.leave {
		transform: translateX(0%);
		animation: leave $animDuration ease-in forwards; } }


@include phone {
	@keyframes enter {
		to {
			transform: translateY(0); } }
	@keyframes push-back {
		to {
			transform: translateY(-20px); } }

	@keyframes leave {
		to {
			transform: translateY(100%); } }
	.modal-window {
		top: auto;
		bottom: 0;
		right: 0;
		left: 0;
		width: auto;
		height: 90%;


		&.enter {
			transform: translateY(100%); }

		&.push-back {
			transform: translateY(0); }
		&.push-front {
			transform: translateY(-20px); }

		&.leave {
			transform: translateY(0%); } } }
