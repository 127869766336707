@import '~colbi_web_ui/lib/style/theme';
@import '~colbi_web_ui/lib/style/mixins';

body {
	padding: 0;
	margin: 0;

	@include css4(color, --text-color);
	@include css4(font-family, --body-font);
	@include css4(font-weight, --body-font-weight);

	-webkit-font-smoothing: antialiased; }

.app {
	@include css4(color, --text-color); }

.header {
	z-index: 10; }


.aside {
	position: fixed;
	left: 0;
	width: $asideWidth;
	top: 0;
	bottom: 0;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	align-items: center;

	@include css4(background, --menu-background-color);

	box-shadow: $smallShadowRight;

	z-index: 1;

	+ .main {
		margin-left: $asideWidth; }

	&.is-collapsed {
		width: $headerHeight;
		z-index: 9;
		& + .main {
			margin-left: $headerHeight; } } }

.tabs {
	position: sticky;
	top: $headerHeight; }

.content {
	padding: $vMargin $hMargin;
	box-sizing: border-box;

	> *:not([stuck]) {
		margin-left: auto;
		margin-right: auto;
		transition: background-color 50ms 100ms; }

	> *[stuck] {
		margin-left: -#{$hMargin};
		margin-right: -#{$hMargin};
		padding-left: calc(#{$hMargin} / #{$phi});
		padding-right: calc(#{$hMargin} / #{$phi});

		// Global style
		z-index: 2;
		@include css4(background-color, --header-background-color);

		transform: translate3d(0, 0, 0);

		border-bottom: 1px solid transparent;
		@include css4(border-bottom-color, --header-border-color);

		@include dropShadow($smallShadow);

		~ *[stuck] {
			border-top: 1px solid transparent;
			@include css4(border-top-color, --header-border-color);
			border-bottom: 1px solid transparent;
			@include css4(border-bottom-color, --header-border-color); } } }

.foreground {
	display: flex;
	flex-flow: row wrap;
	background-color: var(--foreground-color, false);
	background: $appForeground;
	background-size: cover;
	background-repeat: no-repeat;
	> div {
		&:first-child {
			flex: 0 100%; } } }

.loading-indicator {
	position: fixed;
	top: $headerHeight;
	z-index: 10;
	left: 50%;
	transform: translateX(-50%);
	padding: calc(#{$vMargin} / (#{$phi})) 0;
	padding-left: $asideWidth;

	pointer-events: none;

	&.small-margin {
		padding-left: $headerHeight; } }


.--root-margin-top-small {
	&.activity {
		background: red; } }

@include phone {
	.main {
		margin-left: 0px !important; }
	.loading-indicator {
		padding-left: 0px !important; }

	.aside {
		display: none; } }

@include tablet {
	.main {
		margin-left: 0px !important; }
	.loading-indicator {
		padding-left: 0px !important; }

	.aside {
		display: none; } }


@include fullHD {

	.content {
		padding: $vMargin $maxHMargin;
		box-sizing: border-box;

		> *[stuck] {
			margin-left: -#{$maxHMargin};
			margin-right: -#{$maxHMargin};
			padding-left: calc(#{$maxHMargin} / #{$phi});
			padding-right: calc(#{$maxHMargin} / #{$phi}); } } }
