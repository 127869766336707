@import '~colbi_web_ui/lib/style/_layout';
@import '~colbi_web_ui/lib/style/_theme';
@import '~colbi_web_ui/lib/style/_mixins';

.export-button {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px; }

.action-button {
    position: relative;
    width: 35px;
    height: 35px;
    padding: 2px;
    box-sizing: border-box;
    border: none;
    outline: none;
    background: transparent;

    > *:not(.spinner) {

        width: 100%;

        height: 100%;

        @include css4(fill, --header-color); }

    .spinner {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%; }

    &.has-activity > *:not(.spinner) {
        transform: scale(0.7, 0.7); }

    + .action-button {
        margin-left: calc(#{$hMargin} / (2 * #{$phi})); } }
