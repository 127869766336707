@import '~colbi_web_ui/lib/style/_layout';
@import '~colbi_web_ui/lib/style/_theme';
@import '~colbi_web_ui/lib/style/_mixins';

.detail {
    .wrap-buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        a:first-child {
            @include css4(color, --tabs-action-color);
            text-decoration: underline; } }

    .close-button {
        display: flex;
        justify-content: flex-end;
        align-items: center; }

    .close {
        text-align: right;
        @include css4(color, --strong-text-color);
        text-decoration: none;
        font-size: small;
        cursor: pointer; }
    .content {
        margin-top: 10px;
        p {
            @include css4(color, --strong-text-color);
            font-weight: bold;
            margin: 5px 0 0;
            font-size: 11px; }
        h5 {
            margin-bottom: 0; }
        h6 {
            margin: 5px 0 10px;
            font-size: 12px;
            @include css4(color, --text-color); }

        .list-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid transparent;
            @include css4(border-color, --text-color);
            padding-bottom: 15px; }

        span {
            display: block;
            margin-top: 25px;
            font-size: 11px;
            @include css4(color, --text-color);
            text-transform: uppercase;
            font-weight: bold; }

        ul {
            padding: 0;
            list-style: none;
            display: flex;
            li {
                flex: 0 50%;
                padding-right: 10px;
                small {
                    font-size: 11px; }
                p {
                    text-transform: uppercase; } }

            &.list-headers-summary,
            &.list-severity-summary {
                display: block;
                li {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 5px 10px;
                    &:nth-child(even) {
                        @include css4(background, --widget-background-color); } } } }
        .center-items {
            justify-content: space-between;
            align-items: center; }


        &.folders {
            div {
                align-items: center;
                h5 {
                    margin-right: 3px;
                    &:after {
                        content: ': '; } }
                h5, p {
                    margin-bottom: 0;
                    margin-top: 15px; } } } } }

.download-button {
	background: transparent;
	border: 1px solid transparent;
	@include css4(border-color, --header-border-color);
	outline: none;

	* {
		@include css4(fill, --header-strong-color); } }

.spin {
    -webkit-animation: rotating 800ms steps(8, end) infinite; }

.main {
    padding: calc(#{$vMargin} / (1 * #{$phi})) calc(#{$maxHMargin} / (1 * #{$phi})); }

.modal-window {
	position: fixed;
	top: 0;
	right: 0;
	width: 100vw;
	height: 100vh;
	max-width: 800px;

	@include css4(background, --foreground-color);

	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
	overscroll-behavior-y: contain; }

.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.4);
    opacity: 1;
    animation: fade-in 200ms linear forwards; }


.header {
	position: sticky;
	display: flex;
	align-items: center;
	top: 0;
	height: min(40px, $headerHeight);
	padding: calc(#{$vMargin} / (2 * #{$phi})) calc(#{$hMargin} / (2 * #{$phi}));
	box-sizing: border-box;

	@include css4(background, --foreground-color);

	border-bottom: 1px solid var(--widget-border-color, false);
	border-bottom: 1px solid var(--widget-border-color);

	z-index: 1;

	h5 {
		margin: 0; }

	> * {
		display: inline-flex;
		position: relative;
		flex: 0 1 auto;
		min-width: 0;

		&:not(:first-child):not(:last-child) {
			flex: 1; }

		* {
			position: relative;
			white-space: nowrap;
			max-width: 100%;
			text-overflow: ellipsis;
			overflow: hidden; } } }

.close-button {
	display: inline-flex;
	align-items: center;
	margin: 0;
	padding: 0;
	width: 32px;
	height: 32px;
	background: none;
	border: none;
	outline: none;
	cursor: pointer;

	 :global(.icon) {
		width: 24px;
		height: 24px;

		@include css4(fill, --strong-text-color); } }

.loading-indicator {
	position: absolute;
	top: $headerHeight;
	z-index: 12;
	left: 50%;
	transform: translateX(-50%);
	pointer-events: none; }

@-webkit-keyframes rotating {
    from {
        -webkit-transform: rotate(0deg); }
    to {
        -webkit-transform: rotate(360deg); } }

@include phone {
    .detail {
        top: auto;
        position: absolute;
        padding: calc(#{$vMargin} / (2 * #{$phi})) calc(#{$maxHMargin} / (2 * #{$phi})); }

    .modal-window {
        top: auto;
        bottom: 0;
        right: 0;
        left: 0;
        width: auto;
        height: 90%; } }

@include fullHD {
	.modal-header {
		padding: calc(#{$vMargin} / (2 * #{$phi})) calc(#{$maxHMargin} / (2 * #{$phi})); } }
